<template>
  <tr data-locator="documents-file-row" v-on="$listeners">
    <td>
      <v-checkbox v-if="document.isSelectable"
                  class="mt-0 pt-0"
                  hide-details
                  :input-value="selected"
                  :ripple="false"
                  @click.stop="selectDocument"
      />
    </td>

    <td class="column--name">
      <DocumentsTableItemFolderName v-if="document.type === 'folder'" :folder="document" />
      <DocumentsTableItemFileName v-else-if="document.type === 'file'" :file="document" />
    </td>

    <td>
      <app-filesize-display :value="document.size"/>
    </td>

    <td>
      <app-text
        v-if="document.type === 'file'"
        as="span"
        variant="small-regular"
      >
        {{ updateDate }}
      </app-text>
    </td>

    <td>
      {{ document.updateUser }}
    </td>

    <td>
      <RightsDisplayButton
        v-if="document.rights"
        :rights="document.rights"
        :isClickable="document.canShare"
        :hoverOnly="!isCurrentUserPm"
        block
        style="max-width: 150px"
        @click="openDocumentSharePane"
      />
    </td>

    <td v-if="withLocation">
      <DocumentLocationLink :document="document"
                            @openInFolder="openInFolder"
      />
    </td>

    <td>
      <DocumentsTableItemOptions :document="document"
                                 @openInFolder="openInFolder"
      />
    </td>
  </tr>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import RightsDisplayButton from '@/common/users/RightsDisplayButton.vue'
import { ISOToShortenedDate } from '@/common/utils/dates'
import { ROOT_FOLDER } from '@/project/documents/constants/special-folders'
import DocumentLocationLink from '@/project/documents/DocumentLocationLink.vue'
import DocumentsTableItemFileName from '@/project/documents/table/DocumentsTableItemFileName.vue'
import DocumentsTableItemFolderName from '@/project/documents/table/DocumentsTableItemFolderName.vue'
import DocumentsTableItemOptions from '@/project/documents/table/DocumentsTableItemOptions.vue'
import {
  DELETE_SEARCH_RESULTS,
  GET_DOCUMENTS_BY_ID,
  GET_FOLDER_PATH,
  SET_TABLE_DOCUMENTS_PANE,
} from '@/store/modules/documents/action_types'
import { ENQUEUE_SNACKBAR } from '@/store/mutation_types'

export default {
  name: 'DocumentsTableItem',
  components: {
    DocumentsTableItemOptions,
    DocumentLocationLink,
    RightsDisplayButton,
    DocumentsTableItemFileName,
    DocumentsTableItemFolderName,
  },
  props: {
    document: {
      type: Object,
      required: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('documents', ['searchModeEnabled']),
    ...mapGetters('documents', ['isRecentFolder']),
    ...mapGetters('room', ['isCurrentUserPm']),
    updateDate () {
      return ISOToShortenedDate(this.document.updateTime)
    },
    withLocation () {
      return this.searchModeEnabled || this.isRecentFolder
    },
  },
  methods: {
    ...mapActions('documents', [
      DELETE_SEARCH_RESULTS,
      GET_DOCUMENTS_BY_ID,
      GET_FOLDER_PATH,
      SET_TABLE_DOCUMENTS_PANE,
    ]),
    ...mapActions('documentsBreadcrumb', ['setBreadcrumbItems']),
    async getFolderById (folderId) {
      try {
        await this.GET_DOCUMENTS_BY_ID({
          id: folderId,
          queryObject: { markRecent: true },
        })
      } catch (error) {
        console.error(error)

        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$t('project.documents.table.DocumentsTableItem.getDocumentsError'),
        })
      }
    },
    async getParentPath () {
      try {
        return await this.GET_FOLDER_PATH(this.document.parent.id)
      } catch (error) {
        console.error(error)

        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$t('project.documents.table.DocumentsTableItem.getFolderPathError'),
        })
      }
    },
    openDocumentSharePane () {
      this.SET_TABLE_DOCUMENTS_PANE({
        isOpen: true,
        documents: [this.document],
        tab: 'share',
        forcePmSelection: true,
      })
    },
    async openInFolder () {
      if (this.document.parent) {
        const parentPath = await this.getParentPath()

        if (parentPath) {
          await this.getFolderById(this.document.parent.id)
          this.setBreadcrumbItems([ROOT_FOLDER].concat(parentPath))
        }
      } else {
        await this.getFolderById(ROOT_FOLDER.id)
        this.setBreadcrumbItems([ROOT_FOLDER])
      }

      if (this.searchModeEnabled) {
        this.DELETE_SEARCH_RESULTS()
      }
    },
    selectDocument () {
      this.$emit('select')
    },
  },
}
</script>

<style scoped lang="scss">
.column--name {
  max-width: 0;
  width: 50%;
}
</style>
