<template>
  <div class="projects">
    <v-container
      :class="{'pa-4':$vuetify.breakpoint.mobile }"
      class="mb-8"
      fluid
    >
      <v-row>
        <v-col cols="12">
          <ProjectsHeader :display="display"
                          :searchQuery.sync="searchQuery"
                          @display="changeDisplay"
          />
          <ProjectsActiveFilters/>

          <!-- FOLDERS -->
          <ProjectsFolders v-if="!hasRecentRooms && !searchModeEnabled"></ProjectsFolders>

          <!-- ROOMS -->
          <template v-if="isPending && !roomsList?.length">
            <v-row>
              <v-col>
                <v-skeleton-loader
                  v-for="i in 5"
                  :key="i"
                  hide-on-leave
                  class="mx-auto mb-2"
                  type="image"
                  style="border-radius: 10px; background-color:white"
                  height="40"
                ></v-skeleton-loader>
              </v-col>
            </v-row>
          </template>
          <template v-else-if="roomsList?.length">
            <div class="ProjectsHeader-title my-4">
              {{ searchModeEnabled ? $t('projects.ProjectsHeader.searchTitle') : $t('projects.ProjectsHeader.projects') }}
            </div>
            <div v-if="!(searchModeEnabled && roomSearchResults.length === 0)" class="ProjectsHeader-subtitle">{{ headerTitle }}</div>
            <template v-if="searchModeEnabled && !roomSearchResultsPending && roomSearchResults.length === 0">
              <div
                style="max-width: 575px"
                class="text-center mx-auto mt-8 mb-4"
              >
                <app-icon icon-name="magnifying-glass" class="d-inline-block mb-2" />
                <app-text variant="large-bold">
                  {{ $t("projects.search.noResultOnSearch") }}
                </app-text>
                <app-text variant="small-regular">
                  {{ $t("projects.search.noResultOnSearchHint") }}
                </app-text>
              </div>
            </template>
            <template v-else>
              <ProjectsGridTemplate v-show="display === DISPLAY.grid || $vuetify.breakpoint.smAndDown"
                                    :rooms="roomsByRole"
              />
              <ProjectsTableTemplate v-show="display === DISPLAY.table && !$vuetify.breakpoint.smAndDown"
                                    :rooms="roomsByRole"
              />
            </template>
          </template>
        </v-col>
      </v-row>
    </v-container>
    <ProjectsFiltersPane/>
    <DocumentsNewFolderDialog/>
    <ProjectsMoveRoomDialog v-if="moveRoomDialogIsOpen" isRoom/>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { cloneDeep, debounce } from 'lodash-es'
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'

import DocumentsNewFolderDialog from '@/project/documents/dialogs/DocumentsNewFolderDialog.vue'
import ProjectsMoveRoomDialog from '@/projects/dialogs/ProjectsMoveRoomDialog.vue'
import ProjectsFolders from '@/projects/ProjectsFolders.vue'
import { GET_PROJECTS_FOLDER_BY_ID } from '@/store/modules/projects-folders/action_types'
import { SET_CURRENT_PROJECTS_FOLDER } from '@/store/modules/projects-folders/mutation_types'
import { GET_ROOMS_PROGRESSIVELY, GET_RECENT_ROOMS, GET_ROOM_SEARCH_RESULTS_PROGRESSIVELY } from '@/store/modules/rooms/action_types'
import { SET_RECENT_ROOMS, SET_SEARCH_MODE_ENABLED, SET_ROOM_SEARCH_RESULTS } from '@/store/modules/rooms/mutation_types'
import { ENQUEUE_ERROR_SNACKBAR } from '@/store/mutation_types'

import { getRoomsByRole } from '../../common/utils/rooms'
import { DISPLAY } from '../display'
import ProjectsActiveFilters from '../ProjectsActiveFilters'
import ProjectsFiltersPane from '../ProjectsFiltersPane'
import ProjectsGridTemplate from '../ProjectsGridTemplate'
import ProjectsHeader from '../ProjectsHeader'
import ProjectsTableTemplate from '../ProjectsTableTemplate'

const isBetween = require('dayjs/plugin/isBetween')
dayjs.extend(isBetween)
const isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
dayjs.extend(isSameOrAfter)
// We use a multiple of 3 and 4 to have a good layout for the cards to be displayed
const RECENT_ROOMS_LIMIT = 12
const SEARCH_DEBOUNCE_RATE = 1000

export default {
  name: 'Projects',
  components: {
    ProjectsActiveFilters,
    ProjectsFiltersPane,
    ProjectsTableTemplate,
    DocumentsNewFolderDialog,
    ProjectsGridTemplate,
    ProjectsMoveRoomDialog,
    ProjectsHeader,
    ProjectsFolders,
  },
  data: function () {
    return {
      sortKey: 'name',
      filterKey: '',
      sortOrders: {
        state: 1,
        name: -1,
        creationDate: 1,
        dueDate: 1,
        members: 1,
        documents: 1,
        tasks: 1,
        originalDocuments: 1,
        documentsToSign: 1,
      },
      order: 1,
      display: DISPLAY.grid,
      searchQuery: '',
    }
  },
  computed: {
    ...mapGetters('login', ['isFullAccessLogin']),
    ...mapState('room', ['moveRoomDialogIsOpen']),
    ...mapState('rooms', ['rooms', 'filtersPane', 'sort', 'processing', 'displayType', 'recentRooms', 'recentRoomsPending', 'searchModeEnabled', 'roomSearchResults', 'roomSearchResultsPending']),
    ...mapState('projectsFolders', ['currentProjectsFolder', 'projectsFolderByIdPending']),
    ...mapGetters('rooms', ['hasRecentRooms']),
    isPending () {
      return this.projectsFolderByIdPending || this.recentRoomsPending || this.processing
    },
    roomsList () {
      return (this.searchModeEnabled && this.rooms) || this.currentProjectsFolder.rooms || (this.recentRooms.length !== 0 && this.recentRooms) || this.rooms
    },
    headerTitle () {
      if (this.roomsByRole) {
        if (this.roomsByRole.asPM.length > 0) {
          return this.$t('projects.views.Projects.projectManager', { projectsCount: this.roomsByRole.asPM.length })
        } else if (this.roomsByRole.asGuest.length === 0) {
          return this.$t('projects.views.Projects.projectManager', { projectsCount: 0 })
        } else if (this.roomsByRole.asGuest.length > 0) {
          return this.$t('projects.views.Projects.guest', { projectsCount: this.roomsByRole.asGuest.length })
        }
      }
      return this.$t('projects.views.Projects.projectManager', { projectsCount: 0 })
    },
    displayAddNewRoomCard () {
      return this.display === DISPLAY.grid
    },
    DISPLAY () {
      return DISPLAY
    },
    filteredRooms () {
      let filteredRooms = cloneDeep(this.searchModeEnabled ? this.roomSearchResults : this.roomsList)
      if (this.filtersPane.filters.creationDate.length > 0) {
        filteredRooms = filteredRooms.filter(room => {
          return dayjs(room.createdAt).isBetween(dayjs(this.filtersPane.filters.creationDate[0]), dayjs(this.filtersPane.filters.creationDate[1]).add(1, 'day'))
        })
      }
      if (this.filtersPane.filters.dueDate.length > 0) {
        filteredRooms = filteredRooms.filter(room => {
          if (room.expectedClosingDate) {
            return dayjs(room.expectedClosingDate).isBetween(dayjs(this.filtersPane.filters.dueDate[0]), dayjs(this.filtersPane.filters.dueDate[1]))
          }
        })
      }
      if (this.filtersPane.filters.selectedLabels.length > 0) {
        filteredRooms = filteredRooms.filter(room => {
          if (room.label) {
            return this.filtersPane.filters.selectedLabels.some(label => label.id === room.label.id)
          }
        })
      }
      if (this.filtersPane.filters.projectType.length > 0 && this.filtersPane.filters.projectType.length !== 2) {
        if (this.filtersPane.filters.projectType[0] === 'project') {
          filteredRooms = filteredRooms.filter(room => { return !room.isDataroom })
        }
        if (this.filtersPane.filters.projectType[0] === 'dataroom') {
          filteredRooms = filteredRooms.filter(room => { return room.isDataroom })
        }
      }
      if (this.filtersPane.filters.role.length > 0 && this.filtersPane.filters.role.length !== 2) {
        if (this.filtersPane.filters.role[0] === 'projectManager') {
          filteredRooms = filteredRooms.filter(room => { return room.isPM })
        }
        if (this.filtersPane.filters.role[0] === 'guest') {
          filteredRooms = filteredRooms.filter(room => { return !room.isPM })
        }
      }
      if (this.sort.value === 'creationDate') {
        filteredRooms.sort((a, b) => {
          if (dayjs(a.createdAt).isSameOrAfter(dayjs(b.createdAt))) {
            return 1
          } else {
            return -1
          }
        })
      } else if (this.sort.value === 'dueDate') {
        filteredRooms.sort((a, b) => {
          if (a.expectedClosingDate && b.expectedClosingDate) {
            if (dayjs(a.expectedClosingDate).isSameOrAfter(dayjs(b.expectedClosingDate))) {
              return 1
            } else {
              return -1
            }
          } else if (a.expectedClosingDate && !b.expectedClosingDate) return -1
          else if (!a.expectedClosingDate && b.expectedClosingDate) return 1
          else return 0
        })
      } else if (this.sort.value === 'label') {
        filteredRooms.sort((a, b) => {
          return a.name.localeCompare(b.name)
        })
      }
      return filteredRooms
    },
    roomsByRole () {
      let roomsByRole = null
      if (this.filteredRooms && this.filteredRooms.length > 0) {
        roomsByRole = getRoomsByRole(this.filteredRooms)
      }
      return roomsByRole
    },
  },
  watch: {
    $route: {
      handler: async function (to) {
        if (this.isFullAccessLogin) {
          this.resetRecentRooms()
          if (to.params.childId) {
            this.initFolder(to.params.childId)
          } else if (to.params.folderId) {
            if (to.params.folderId === 'recent') {
              this.killFolder()
              this.initRecentProjects()
            } else {
              this.initFolder(to.params.folderId)
            }
          } else {
            this.killFolder()
            this.GET_ROOMS_PROGRESSIVELY()
          }
        }
        this.resetSearch()
      },
      immediate: true,
    },
    searchQuery () {
      this.debouncedSearch(this.searchQuery)
    },
  },
  created () {
    this.debouncedSearch = debounce(this.GET_ROOM_SEARCH_RESULTS_PROGRESSIVELY, SEARCH_DEBOUNCE_RATE)
  },
  mounted () {
    this.setHomeLayout(true)
    this.changeDisplay(this.displayType)
  },
  beforeDestroy () {
    this.setHomeLayout(false)
    this.killFolder()
    this.resetRecentRooms()
  },
  methods: {
    ...mapMutations('projectsFolders', [SET_CURRENT_PROJECTS_FOLDER]),
    ...mapMutations('rooms', [SET_RECENT_ROOMS, SET_ROOM_SEARCH_RESULTS, SET_SEARCH_MODE_ENABLED]),
    ...mapActions(['setHomeLayout']),
    ...mapActions('rooms', [GET_ROOMS_PROGRESSIVELY, GET_RECENT_ROOMS, GET_ROOM_SEARCH_RESULTS_PROGRESSIVELY]),
    ...mapActions('projectsFolders', [GET_PROJECTS_FOLDER_BY_ID]),
    ...mapMutations([ENQUEUE_ERROR_SNACKBAR]),
    sortBy: function (key) {
      this.sortKey = key
      this.sortOrders[key] = this.sortOrders[key] * -1
    },
    changeDisplay (display) {
      this.display = display
    },
    async initFolder (folderId) {
      try {
        await this.GET_PROJECTS_FOLDER_BY_ID({
          folderId,
        })
      } catch (error) {
        // Return to previous URL
        this.$router.go(-1)
        this.ENQUEUE_ERROR_SNACKBAR(this.$t('project.documents.dialogs.DocumentsNewFolderDialog.getFolderError', { name: '' }))
      }
    },
    async initRecentProjects () {
      try {
        await this.GET_RECENT_ROOMS({
          limit: RECENT_ROOMS_LIMIT,
        })
      } catch (error) {
        // Return to previous URL
        this.$router.go(-1)
        this.ENQUEUE_ERROR_SNACKBAR(this.$t('project.documents.dialogs.DocumentsNewFolderDialog.getFolderError', { name: '' }))
      }
    },
    killFolder () {
      this.SET_CURRENT_PROJECTS_FOLDER({})
    },
    resetRecentRooms () {
      this.SET_RECENT_ROOMS([])
    },
    resetSearch () {
      // Even if it's done in the action itself since we set the searchQuery to an empty string
      // We empty the state right away to be more responsive in that instance
      this.SET_ROOM_SEARCH_RESULTS([])
      this.SET_SEARCH_MODE_ENABLED(false)
      this.searchQuery = ''
    },
  },
}
</script>

<style scoped>
  .ProjectsHeader-title {
    font-size: 21px;
    font-weight: 600;
  }
  .ProjectsHeader-subtitle {
    font-size: 18px;
    font-weight: 600;
  }

  /* transition list */
  .list-complete-item {
    transition: all 0.5s;
  }

  .list-complete-enter,
  .list-complete-leave-to {
    opacity: 0;
  }

  .list-complete-leave-active {
    position: absolute;
    z-index: -1;
  }
</style>
